/* Root level containers */
html, body, #root {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
}

/* Progress Bar */
.progress-container {
  padding: 1rem 1.25rem;
  background-color: white;
  border-bottom: 1px solid #E5E7EB;
  position: sticky;
  top: 0;
  z-index: 10;
  flex-shrink: 0;
}

.progress-background {
  height: 6px;
  background-color: #E5E7EB;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.progress-fill {
  height: 100%;
  background-color: black;
  border-radius: 3px;
  transition: width 600ms ease-in-out;
}

.progress-text {
  font-size: 0.875rem;
  color: #6B7280;
  text-align: center;
}

/* Content */
.play-container {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  background-color: #F5F5F7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-app-div {
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  position: relative;
}

.questions-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F7;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.questions-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  padding: 1.25rem;
  min-height: calc(100% - 80px); /* Subtract progress bar height */
}

.question-card {
  background-color: white;
  border-radius: 1rem;
  padding: 1.5rem;
  width: 100%;
  max-width: 42rem;
  margin: 0 auto; /* Center horizontally */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.question-number {
  font-size: 1.25rem;
  font-weight: 700;
  color: black;
}

.question-text {
  font-size: 1.125rem;
  line-height: 1.625;
  color: #1F2937;
  margin-bottom: 2rem;
}

.answers-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.answer-button {
  padding: 1rem;
  border-radius: 0.75rem;
  background-color: white;
  border: 1px solid #E5E7EB;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  text-align: left;
}

.answer-button:hover:not(.answered):not(.disabled) {
  border-color: #D1D5DB;
  background-color: #F9FAFB;
}

.answer-button:disabled {
  cursor: not-allowed;
}

.answer-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #E5E7EB;
  background-color: #F3F4F6;
}

.answer-text {
  font-size: 1rem;
  color: #1F2937;
  flex: 1;
  text-align: left;
  padding-right: 1rem;
}

.answered {
  color: white;
  font-weight: 500;
}

.correct-answer .answer-text,
.wrong-answer .answer-text {
  color: white;
}

.correct-answer {
  background-color: #06BF66;
  border-color: #06BF66;
}

.wrong-answer {
  background-color: #DC2626;
  border-color: #DC2626;
}

.result-indicator {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.75rem;
  background-color: white;
}

.indicator-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #1F2937;
  line-height: 0;
}

.correct-answer .indicator-text {
  color: #06BF66;
}

.wrong-answer .indicator-text {
  color: #DC2626;
}

/* Responsive Design */
@media (min-width: 768px) {
  .question-card {
    padding: 2rem;
  }

  .question-number {
    font-size: 1.5rem;
  }

  .question-text {
    font-size: 1.25rem;
  }

  .answer-text {
    font-size: 1.125rem;
  }
}

/* Animation Classes */
.fade-in {
  animation: fadeIn 400ms ease-in;
}

.fade-out {
  animation: fadeOut 200ms ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 16px;
} 