/* Container Styles */
.congratulations-container {
  min-height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.content {
  flex: 1;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
}

.main-content {
  flex: 1;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 0;
}

.header-section {
  text-align: center;
  margin-bottom: 40px;
  padding: 0;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 4px;
  color: #000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
}

.subtitle {
  font-size: 15px;
  color: #666;
  margin-bottom: 32px;
  font-weight: 400;
}

.stats-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 32px;
  margin-bottom: 48px;
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
}

.stat-number {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
  color: #000;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto;
}

.stat-label {
  font-size: 13px;
  color: #666;
  font-weight: 400;
}

.actions-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
}

.compare-button {
  width: 90%;
  max-width: none;
  background-color: #fff;
  border-radius: 16px;
  padding: 16px;
  border: 1px solid #000;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 200ms ease;
}

.compare-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.compare-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 8px;
}

.compare-text {
  flex: 1;
  font-size: 15px;
  color: #000;
  margin: 0;
  font-weight: 400;
}

.how-to-calculate-button {
  margin-bottom: 32px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.how-to-calculate-text {
  font-size: 13px;
  color: #666;
  text-decoration: underline;
  font-weight: 400;
}

.share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 12px 24px;
  border-radius: 24px;
  margin-bottom: 40px;
  border: none;
  cursor: pointer;
  transition: all 200ms ease;
  min-width: 120px;
}

.share-button:active {
  opacity: 0.7;
}

.share-button-text {
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
}

.share-icon {
  margin-left: 4px;
  color: #fff;
}

.congratulations-container .bottom-text {
  text-align: center !important;
  font-size: 15px !important;
  margin-top: 8px !important;
  color: #666 !important;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
  line-height: 1.4 !important;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto !important;
}

.congratulations-container .countdown-text {
  font-size: 15px !important;
  color: #666 !important;
  text-align: center !important;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto !important;
  font-weight: 400 !important;
  line-height: 1.2 !important;
  margin: 0 !important;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 16px;
  width: 92%;
  max-width: 400px;
  padding: 20px;
  max-height: 90%;
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.modal-close {
  padding: 0px;
  cursor: pointer;
  font-size: 25px;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000;
}

.modal-ul-bullets {
  list-style-type: disc;
  padding-left: 20px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}

.modal-ul-bullets li {
  font-size: 15px;
  line-height: 1.4;
  color: #333;
}

/* Carousel Modal Styles */
.carousel-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100%;
  height: calc(var(--vh, 1vh) * 100);
  backdrop-filter: blur(4px);
}

.carousel-modal-content {
  background-color: white;
  border-radius: 16px;
  width: 92%;
  max-width: 400px;
  padding: 16px 16px 0;
  height: calc(var(--vh, 1vh) * 95);
  max-height: calc(var(--vh, 1vh) * 95);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  margin: 20px;
}

.carousel-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.carousel-modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.carousel-close-button {
  padding: 4px;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-wrapper {
  flex: 1;
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.carousel {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 24px;
}

.carousel-item {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;
  padding-bottom: 96px;
  justify-content: center;
}

.carousel-item.first-card {
  justify-content: center;
  padding: 32px 20px;
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.congrats-content {
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.congrats-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #000;
}

.congrats-text {
  font-size: 16px;
  margin-bottom: 16px;
  padding: 0 16px;
  line-height: 1.4;
  color: #333;
  font-weight: 400;
}

.ranking-percentage {
  font-size: 64px;
  font-weight: 700;
  margin: 16px 0;
  color: #000;
}

.swipe-text-container {
  margin-top: 16px;
  text-align: center;
}

.swipe-text {
  font-size: 15px;
  color: #666;
  font-weight: 400;
}

.question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  min-height: 48px;
  padding: 8px 0;
}

.question-number {
  font-size: 24px;
  font-weight: 600;
  color: #1F2937;
  display: flex;
  align-items: center;
}

.trial-number {
  font-size: 16px;
  color: #6B7280;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.question-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 0;
}

.question-text {
  font-size: 18px;
  color: #4B5563;
  margin-top: 0;
  margin-bottom: 24px;
  line-height: 24px;
  text-align: left;
  align-self: flex-start;
  padding: 0 4px;
}

.answer-stats {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 4px;
  margin-bottom: 48px; /* Space for legend */
}

.answer-stat {
  position: relative;
  border-radius: 25px;
  border: 1px solid #E5E7EB;
  overflow: hidden;
  background-color: #F3F4F6;
}

.answer-stat.correct {
  background-color: #06BF66;
  border-color: #06BF66;
}

.percentage-bar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.percentage-fill {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #E5E7EB;
  transition: width 0.3s ease;
}

.correct .percentage-fill {
  background-color: #05a558;
}

.answer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 16px;
  position: relative;
  z-index: 1;
}

.answer-text-container {
  flex: 1;
  margin-right: 8px;
}

.answer-text {
  font-size: 16px;
  font-weight: 500;
  color: #4B5563;
}

.correct .answer-text {
  color: #fff;
  font-weight: 600;
}

.answer-right-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.response-count {
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: rgba(0,0,0,0.05);
  padding: 4px 8px;
  border-radius: 12px;
}

.response-count-text {
  font-size: 16px;
  font-weight: 500;
  color: #4B5563;
  min-width: 45px;
  text-align: right;
}

.correct .response-count-text {
  color: #fff;
}

.legend {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 16px 4px;
  background-color: white;
  position: absolute;
  bottom: 48px;
  left: 0;
  right: 0;
  border-top: none;
  z-index: 2;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 6px;
}

.legend-text {
  font-size: 13px;
  color: #666;
  font-weight: 400;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  gap: 6px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 48px;
  z-index: 1;
}

.pagination-dot {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  cursor: pointer;
}

.pagination-dot.active {
  background-color: #000;
  transform: scale(1.2);
}

.carousel-nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 3;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.carousel-nav-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.carousel-nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.carousel-nav-button.prev {
  left: -12px;
}

.carousel-nav-button.next {
  right: -12px;
}

.carousel-nav-button img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.carousel-nav-button.prev img {
  transform: rotate(180deg);
}

@media (max-width: 768px) {
  .carousel {
    margin: 0;
  }
  
  .carousel-nav-button {
    display: none;
  }
}

@media (min-width: 768px) {
  .content {
    padding: 24px;
    align-items: center;
  }

  .title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 17px;
  }

  .stat-number {
    font-size: 42px;
  }

  .stat-label {
    font-size: 15px;
  }

  .compare-button {
    width: 90%;
    max-width: none;
  }

  .compare-text {
    font-size: 16px;
  }

  .share-button {
    min-width: 140px;
  }

  .share-button-text {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .carousel-modal-content {
    margin: 2px;
    /* height: calc(var(--vh, 1vh) * 98); */
    max-height: calc(var(--vh, 1vh) * 98);
    border-radius: 16px;
    width: calc(100% - 32px);
  }

  .carousel-item {
    padding: 12px;
    padding-bottom: 88px;
  }

  .carousel-item.first-card {
    padding: 24px 16px;
    gap: 16px;
  }

  .question-header {
    margin-bottom: 12px;
  }

  .answer-stats {
    margin-bottom: 40px;
  }

  .legend {
    bottom: 40px;
    padding: 12px 4px;
  }

  .pagination-container {
    height: 40px;
  }

  .answer-text {
    font-size: 15px;
  }

  .ranking-percentage {
    margin: 12px 0;
  }

  .swipe-text-container {
    margin-top: 12px;
  }
} 