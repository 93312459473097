/* Home Screen Styles */
.min-h-screen {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Settings Dropdown */
.setting-div {
  position: fixed;
  top: 80px;
  right: 20px;
  width: 320px;
  background-color: white;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  z-index: 1001;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.close-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  background-color: #f5f5f5;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-icon:hover {
  background-color: #ebebeb;
  transform: scale(1.05);
}

.setting-text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
  padding-right: 40px;
}

.setting-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  margin: 0 -16px;
  border-radius: 12px;
  transition: all 0.2s ease;
}

.setting-section:last-child {
  border-bottom: none;
}

.setting-section:hover {
  background-color: #f5f5f5;
}

.setting-section p {
  font-size: 15px;
  color: #333;
  margin: 0;
  font-weight: 500;
}

.link-text {
  font-size: 15px;
  color: #007AFF;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-weight: 500;
  transition: opacity 0.2s ease;
}

.link-text:hover {
  opacity: 0.8;
}

/* Settings Backdrop */
.settings-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1000;
  backdrop-filter: blur(2px);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  border-radius: 16px;
  width: 92%;
  max-width: 400px;
  padding: 24px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-close {
  float: right;
  cursor: pointer;
  font-size: 24px;
  margin-bottom: 16px;
}

.modal-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
  color: #000;
}

.modal-body {
  font-size: 15px;
  line-height: 1.5;
  color: #333;
}

.modal-components {
  margin-bottom: 24px;
}

.modal-h2-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  color: #000;
}

.modal-ul-bullets {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 16px;
}

.modal-ul-bullets li {
  margin-bottom: 8px;
}

.modal-image {
  max-width: 100%;
  height: auto;
  margin: 16px 0;
}

hr {
  border: none;
  border-top: 1px solid #E5E7EB;
  margin: 16px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .setting-div {
    width: calc(100% - 32px);
    right: 16px;
    left: 16px;
    transform: none;
    margin: 0;
    top: 70px;
  }
  
  .modal-content {
    width: 95%;
    margin: 16px;
    max-height: calc(100vh - 32px);
  }
} 